body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f7; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757575; 
}

.custom-scroll::-webkit-scrollbar {
  height: 5px;
}

/* Set the style and color of the scrollbar track */
.custom-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 2px;
}

/* Set the style and color of the scrollbar thumb */
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #757575; 
}

.custom-intro-tooltip-class {
  background-color: #6200EE !important;
  color: white;
  min-width: 360px !important;
  line-height: 1.5rem;
}

.custom-intro-tooltip-class .introjs-bullets {
  display: none;
}

.custom-intro-tooltip-class .introjs-arrow.top {
  border-bottom-color: #6200EE;
}

.custom-intro-tooltip-class .introjs-skipbutton {
  color: #fff;
}

.custom-member-tooltip-intro {
  min-width: 65vh !important;
  @media screen and (max-width: 768px) {
    min-width: 400px !important;
  }
  @media screen and (max-width: 400px) {
    min-width: 320px !important;
  }
  @media screen and (max-width: 330px) {
    min-width: 270px !important;
  }
  @media screen and (max-width: 280px) {
    min-width: min-content !important;
  }
}

.introjs-tooltipbuttons {
  @media screen and (max-width: 280px) {
    display: flex;
    flex-direction: column;
    text-align: center !important;
  }
}

.custom-member-tooltip-intro .introjs-button.introjs-nextbutton {
    text-shadow: none;
    color: white;
    background-color: #6200EE;
    border-color: #6200EE;
}

.custom-member-tooltip-intro .introjs-button.introjs-nextbutton:hover {
    color: white;
    border-color: #6200EE;
    background-color: #6200EE;
}

.custom-member-tooltip-intro .introjs-button.introjs-nextbutton:focus {
  box-shadow: none;
}

.introjs-overlay {
  z-index: 1 !important;
}

.introjs-tooltiptext {
  font-size: 15px;
  line-height: 1.5rem;
  text-align: left;
}

.intro-image {
  width: 100%;
  margin-top: 25px;
}

.intro-direct-action {
  z-index: initial !important;
  box-shadow: none !important;
}

.custom-direct-action-tooltip-class {
  top: 78px !important;
  @media screen and (min-width: 1280px) {
    left: -15px !important;
  }
  @media screen and (max-width: 1280px) {
    top: 68px !important;
  }
}

.custom-direct-action-tooltip-class .introjs-arrow.top {
  @media screen and (min-width: 1280px) {
    left: 40px !important;
  }
  left: 25px !important;
}