/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.test {
  font-size: 13px;
  font-family: Inter, Roboto, Whitney SSm A, Whitney SSm B, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI';
  font-weight: 400;
  line-height: 1.4375em;
  color: #223354;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 6px;
} */

.ql-snow .ql-editor pre.ql-syntax {
  background-color: white;
  color: black;
}

.mobile-view {
  display: none;
}

@media screen and (max-width: 956px) {
  .mobile-view {
    display: block;
  }
    
  .desktop-view {
    display: none;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-rounded {
  border: 1px solid #6200EE;
  border-radius: 15px;
  background-color: #FAF6FF;
}

.MuiList-root.MuiMenu-list {
  padding: 0px;
}
